import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const routes = [
//     {
//         path: '/',
//         redirect: '/index'
//     },
//     {
//         path: '/index',
//         name: 'index',
//         component: () => import('../views/IndexView.vue')
//     },
//     {
//         path: '/product',
//         name: 'product',
//         component: () => import('../views/ProductView.vue'),
//     },
//     {
//         path: '/product/productId/:productId',
//         name: 'productDetails',
//         component: () => import("../views/ProductDetailsView.vue")
//     },
//     {
//         path: '/example',
//         name: 'example',
//         component: () => import('../views/ExampleView.vue')
//     },
//     {
//         path: '/news',
//         name: 'news',
//         component: () => import('../views/NewsView.vue')
//     },
//     {
//         path: '/news/newsId/:newsId',
//         name: 'newsDetails',
//         component: () => import('../views/NewsDetailsView.vue')
//     },
//     {
//         path: '/about',
//         name: 'about',
//         component: () => import('../views/AboutView.vue')
//     }
// ]

// const router = new VueRouter({
//     mode: 'hash',
//     base: process.env.BASE_URL,
//     routes
// })


//默认路由
export const routes = [
    {
        path: '/',
        redirect: '/index'
    },
];
//pc端的路由
export const pcRoutes = [
    // {
    //     path: '/',
    //     redirect: '/index'
    // },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/pc/IndexView.vue')
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('../views/pc/ProductView.vue'),
    },
    {
        path: '/product/productId/:productId',
        name: 'productDetails',
        component: () => import("../views/pc/ProductDetailsView.vue")
    },
    {
        path: '/example',
        name: 'example',
        component: () => import('../views/pc/ExampleView.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/pc/NewsView.vue')
    },
    {
        path: '/news/newsId/:newsId',
        name: 'newsDetails',
        component: () => import('../views/pc/NewsDetailsView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/pc/AboutView.vue')
    }
];
//移动端设备路由
export const mobileRoutes = [
    // {
    //     path: '/',
    //     redirect: '/index'
    // },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/mobile/IndexView.vue')
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('../views/mobile/ProductView.vue'),
    },
    {
        path: '/product/productId/:productId',
        name: 'productDetails',
        component: () => import("../views/mobile/ProductDetailsView.vue")
    },
    {
        path: '/example',
        name: 'example',
        component: () => import('../views/mobile/ExampleView.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/mobile/NewsView.vue')
    },
    {
        path: '/news/newsId/:newsId',
        name: 'newsDetails',
        component: () => import('../views/mobile/NewsDetailsView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/mobile/AboutView.vue')
    }
];

const createRouter =()=> new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    mode: "hash",
    base: process.env.BASE_URL,
    routes: routes,
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}


export default router
